<template>
	<div id="chiefAnalyst">
		<!-- 占位 -->
		<div class="seizeSeat"></div>
		<!-- 内容 -->
		<div class="chiefAnalystBox">
			<div class="bannerTitle" @click="jumpLink()">{{ $t('chiefAnalyst.banner.title') }}</div>
			<div class="bannerDesc">{{ $t('chiefAnalyst.banner.desc') }}</div>
			<div class="bannerVideo" v-if="videoInfo.playUrl">
				<VueAliplayer :vid="vid" @clickScreen="jumpLink" :source="videoInfo.playUrl" :cover="videoInfo.cover" :options="options"></VueAliplayer>
							
				<!-- <img src="@/assets/images/banner/chiefBanner.png"/> -->
				<!-- <video class="video" id="player-container-id" preload="auto" playsinline webkit-playsinline>
				</video> -->
			</div>
			<div class="bannerVideo" v-else>
				<img :src="videoInfo.cover"/>
			</div>
			<div class="morevideo">
				<span>{{ $t('buttom.moreVideo') }}:</span>
				<a href="https://ychuipro.com" target="_blank">https://ychuipro.com</a>
			</div>
			<div class="teacherTitle">{{ $t('chiefAnalyst.teacherTitle') }}</div>
			<div class="teacherBox">
				<!-- <div class="sameTeacher" v-for="(item,index) in teacher" :key="index">
					<img class="headImg" :src="item.avatar"/>
					<div class="title">{{item.nickName}}</div>
					<div class="desc">{{item.profile}}</div>
					<div class="button" @click="info = item,hideShow = !hideShow">
						<span>{{ $t('buttom.readMore') }}</span>
						<img src="@/assets/images/img/moregrey.png"/>
					</div>
				</div> -->
				<div class="sameTeacher" v-for="(item,index) in $t('chiefAnalyst.teacherList')" :key="index">
					<img class="headImg" :src="item.img"/>
					<!-- <div class="title">{{item.title}}</div> -->
					<div class="desc">{{item.desc}}</div>
					<div class="button" @click="info = item,hideShow = !hideShow">
						<span>{{ $t('buttom.readMore') }}</span>
						<img src="@/assets/images/img/moregrey.png"/>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
		<!-- 隐藏 -->
		<div class="hideBox" v-if="hideShow"></div>
		<div class="hideBoxCon" v-if="hideShow" @click="hideShow = !hideShow">
			<div class="hideBoxConCon" @click.stop>
				<img class="close" @click="hideShow = !hideShow" src="@/assets/images/img/close.png"/>
				<!-- <img class="headImg" :src="info.avatar"/>
				<div class="title">{{info.nickName}}</div>
				<div class="desc">{{info.profile}}</div> -->
				<img class="headImg" :src="info.img"/>
				<!-- <div class="title">{{info.title}}</div> -->
				<div class="desc">{{info.desc}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VueAliplayer from "vue-aliplayer-v2";
	 
	// import TCPlayer from 'tcplayer.js';
	// import 'tcplayer.js/dist/tcplayer.min.css';
	export default {
		name: 'chiefAnalyst',
		components:{
			 VueAliplayer,
		},
		data() {
			return {
				vid:'pc_jPrismPlayer',
				teacher:[],
				info:{},
				hideShow:false,
				videoInfo:{},
				options:{
					width: '100%',                //播放器宽度
					height: '7.1rem',              //播放器高度
					isLive: false,                 //播放内容是否为直播，直播时会禁止用户拖动进度条。
					playsinline:true,             //H5 是否内置播放
					useH5Prism:true,              //指定使用 H5 播放器。
					rePlay:false,                 //播放器自动循环播放.
					preload:true,                 //播放器自动加载，目前仅 h5 可用。
					controlBarVisibility:'hover', //控制面板的实现，默认为‘hover’。可选的值为：‘click’、‘hover’、‘always’。
					autoplay:false,               //播放器是否自动播放，在移动端 autoplay 属性会失效。Safari11 不会自动开启自动播放如何开启。
					enableSystemMenu:true,        //是否允许系统右键菜单显示，默认为 false。
					loadDataTimeout:5,            //缓冲多长时间后，提示用户切换低清晰度，默认：20 秒。
					showBarTime:'10000',          //控制栏自动隐藏时间（ms）。
					disableSeek:true,            //禁用进度条的 Seek，默认为 false，仅 Flash 支持。
				}
			}
		},
		mounted() {
			
		},
		created() {
			// this.teacherList();
			this.roomInfo();
			// console.log(this.markets[this.navIndex].six)
		},
		methods: {
			jumpLink(event){
				window.open('https://ychuipro.com?roomId=1662368982976761857&source=XC')
			},
			teacherList(){
				this.$http.get('xcmarket/teacherList').then((res)=>{
					this.teacher = res.data;
					console.log(this.teacher)
					
				}).catch((err)=>{
				   console.log(err) 
				   // loading.close();
				})
			},
			roomInfo(){
				var that = this;
				const loading = this.$loading({
				  lock: true,
				  text: this.$t('eMsg.loading'),
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.3)'
				});
				that.$http.get('xcmarket/roomInfo?roomId=1662368982976761857').then((res)=>{
					that.videoInfo = res.data;
					console.log(that.videoInfo)
					// var player = TCPlayer('player-container-id', {
					//     sources: [{
					//       src: that.videoInfo.playUrl,
					// 	  poster:that.videoInfo.qrCode,
					//     }],
					//     licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1308111394_1/v_cube.license',
					// }); 
					// player-container-id 为播放器容器 ID，必须与 html 中一致
					 // player.src(that.videoInfo.playUrl); 
					 // url 播放地址
					// player.src(url); // url 播放地址
					loading.close();
				}).catch((err)=>{
				   console.log(err) 
				   loading.close();
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.morevideo{
		width: 100%;
		height: auto;
		padding: 0.5rem 0 0;
		display: flex;
		align-items: center;
		font-size: 0.2rem;
		>a{
			font-size: 0.2rem;
			// font-family: 'Poppins-SemiBold';
			color: #1C1E53;
		}
	}
	.hideBox{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 20;
		background: rgba(0, 0, 0, 0.3);
	}
	.hideBoxCon{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 21;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.hideBoxConCon{
			width: 50%;
			height: auto;
			background: #ffffff;
			border-radius: 0.2rem;
			padding:0.5rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;
			align-items: center;
			.close{
				width: 0.4rem;
				width: 0.4rem;
				position: absolute;
				right: -0.2rem;
				top: -0.2rem;
				z-index: 22;
			}
			.headImg{
				width: 3.8rem;
				height: 2.15rem;
				display: block;
			}
			.title{
				width: 100%;
				height: 0.6rem;
				font-size: 0.24rem;
				color: #282938;
				font-family: 'Poppins-Medium';
				padding: 0.1rem 0 0;
				display: flex;
				align-items: center;
			}
			.desc{
				width: 100%;
				height:auto;
				line-height: 0.3rem;
				font-size: 0.16rem;
				color: #666772;
				font-family: 'Poppins-Regular';
				margin-top: 0.3rem;
			}
		}
	}
	.chiefAnalystBox{
		width: 100%;
		height: auto;
		padding: 0.4rem 18.75% 2rem;
		.bannerTitle{
			width: 100%;
			height: 0.9rem;
			line-height: 0.45rem;
			display: flex;
			align-items: center;
			font-size: 0.32rem;
			color: #282938;
			font-family: 'Poppins-Medium';
			cursor: pointer;
		}
		.bannerDesc{
			width: 100%;
			height:auto;
			line-height: 0.35rem;
			font-size: 0.26rem;
			color: #282938;
			font-family: 'Poppins-Regular';
			margin-bottom: 0.5rem;
		}
		.bannerVideo{
			width: 100%;
			height: auto;
			display: flex;
			justify-content: center;
			align-items: center;
			>img{
				width: 100%;
				height: 7.1rem;
				display: block;
			}
			.video{
				width: 100%;
				height: 7.1rem;
			}
		}
		.teacherTitle{
			width: 100%;
			height: 1.9rem;
			padding: 0.2rem 0 0;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.48rem;
			color: #282938;
			font-family: 'Poppins-SemiBold';
		}
		.teacherBox{
			width: 100%;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.sameTeacher{
				width: 3.8rem;
				height: 4.3rem;
				display: flex;
				flex-direction: column;
				// justify-content: space-between;
				margin-left: 0.2rem;
				margin-bottom: 0.5rem;
				.headImg{
					width: 100%;
					height: 2.15rem;
				}
				.title{
					width: 100%;
					height: 0.6rem;
					font-size: 0.24rem;
					color: #282938;
					font-family: 'Poppins-Medium';
					padding: 0.1rem 0 0;
					display: flex;
					align-items: center;
				}
				.desc{
					width: 100%;
					height:0.9rem;
					line-height: 0.3rem;
					font-size: 0.16rem;
					color: #666772;
					font-family: 'Poppins-Regular';
					white-space: normal;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
					margin-top: 0.2rem;
				}
				.button{
					width: 100%;
					height: 0.5rem;
					display: flex;
					align-items: center;
					font-size: 0.16rem;
					color: #282938;
					font-family: 'Poppins-Medium';
					cursor: pointer;
					>img{
						width: 0.26rem;
						height: 0.26rem;
						margin-left: 0.1rem;
					}
				}
			}
		}
	}
	@media only screen and (max-width:1080px){
		.chiefAnalystBox{
			padding: 0.5rem 0.5rem 1rem;
			.bannerVideo{
				height: auto;
				.video{
					width: 100%;
					height: 5.5rem;
				}
				>img{
					width: 100%;
					height: 5.5rem;
					display: block;
				}
			}
			.teacherBox{
				.sameTeacher{
					width: 100%;
					height:auto;
					margin-bottom: 0.5rem;
					.headImg{
						width: 100%;
						height: auto;
					}
					.title{
						height: 0.8rem;
						font-size: 0.3rem;
					}
					.desc{
						height:1.2rem;
						line-height: 0.4rem;
						font-size: 0.26rem;
						margin-top: 0.3rem;
					}
					.button{
						height: 0.8rem;
						font-size: 0.26rem;
						>img{
							width: 0.26rem;
							height: 0.26rem;
							margin-left: 0.1rem;
						}
					}
				}
			}
		}
		.hideBoxCon{
			.hideBoxConCon{
				width: 90%;
				.close{
					transform: scale(1.3);
				}
				.headImg{
					width: 100%;
					height: auto;
					display: block;
				}
				.title{
					font-size: 0.3rem;
				}
				.desc{
					line-height: 0.4rem;
					font-size: 0.26rem;
				}
			}
		}
		
	}
</style>