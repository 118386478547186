module.exports = {
	footer:{
		footer:'Caution:Transactions may result in financial losses. To trade only with the funds you are prepared to lose, you must recognize that you may lose all the funds in your trading account due to factors beyond your control. We do not know the level of funds you trade or the level of risk you take on each trade. You must make your own financial decisions and we accept no responsibility for the resulting gains or losses.',
		leftTitle:'WeProTrade',
		leftDesc:'We are always open to discuss your project and improve your online presence.',
		emailTitle:'Email us at',
		emailDesc:'eyu8396@gmail.com',
		whatsTitle:'WhatsApp',
		whatsDesc:'0927 6277 28525',
		telegram:"https://t.me/USD8396",
		aboutUs:'About Us',
		aboutDesc:'WeProTrade is an information exchange platform. All information contained on this site is the personal opinion or belief of the author. None of it constitutes a recommendation or financial advice in any sense.'
	},
	login: {
	    signUp:"Sign up",
	    signIn: "Sign in",
		signOut:"Sign out",
		submit:'Submit',
		tips:'Account review in progress',
		signtips:'Confirm logout?'
	},
	language:{
		en:'English',
		zh:'Chinese',
		home:'home'
	},
	buttom:{
		readMore:"Read More",
		removeObstacles:"Go to activate",
		reviews:"Reviews",
		payLeft:"Billing details",
		payRight:"YOUR ORDER",
		product:"Product",
		subtotal:"Subtotal",
		number:"Number",
		total:"Total",
		policy:"Your personal data will be used to process your order.support your experience throughout this website, and forother purposes described in our privacy policy",
		condition:"I have read and agree to the website terms andconditions",
		placeOrder:"PLACE ORDER",
		choocePlan:"Chooce Plan",
		moreVideo:"For more live videos",
		canpay:"Cancel payment！",
		paySuc:"Payment success！",
		payFail:"Payment failed",
		back:"Back",
		tips:"Please enter ",
		tipss:"Please select ",
	},
	eMsg:{
		loading:"loading"
	},
	// {id:1,title:'Chief Analyst Live',src:'/chiefAnalyst',show:false},{id:2,title:'Indictor Education Iive',src:'/indicatorAnalyst',show:true}
	nav:[
		{id:1,title:'Home',src:'/',list:[],show:true},
		{id:2,title:'Latest Results',src:'#latestResults',list:[],show:true},
		// {id:3,title:'Our Tools',src:'/',list:[{title:'Huipro Radar Indicator',show:true,src:'#i2'},{title:'Huipro Trade Copier',show:true,src:'#ourTools'}],show:true},
		{id:3,title:'Our Tools',src:'#i2',list:[],show:true},

		{id:4,title:'Products',src:'#Membership',list:[],show:true},
		// {id:3,title:'Competition Accounts',src:'#latestResults',list:[],show:true},
		// {id:7,title:'Recommended Broker',src:'https://secure.cwgchinese.com/#/signup/23544/F2/B1',list:[],show:true},
		// {id:8,title:'Global cooperation',src:'/',list:[],show:true},
		{id:9,title:'About Us',src:'#footer',list:[],show:true},
		{id:10,title:'FAQ',src:'#answerQuestions',list:[],show:true}
	],
	index:{
		banner:{
			title:"XC Markets Service",
			subtitle:'Education for finance and fintech',
			src:require("@/assets/images/banner/indexBanner.png")
		},
		indexOne:{
			subTitle:'Use Client-first',
			title:'Financial Training',
			desc:'Designed to improve your financial knowledge and help you improve your trading skills so you can trade with more confidence! Whether you are a beginner or an experienced trader, our experienced market analyst training, short video course instruction will explain financial knowledge to you. We are committed to being with you every step of the way as you grow in your financial literacy, laying the foundation for your financial trading by providing valuable financial education.'
		},
		indexTwo:{
			subTitle:'Use Client-first',
			title:'Live Stream',
			desc:"The world's only full-time 10-hour live video broadcast of analysts online, taking you to interpret the major news and events in the international economic markets and analyze the trading strategies of popular products. Join us for an online video training where our chief analysts will share their trading knowledge and answer your questions.<br/>Live indicators 24 hours a day, get quality technical support to help you discover the secrets of successful trend trading, online teaching, easy grasp."
		},
		indexThree:{
			subTitle:'Use Client-first',
			title:'Trading Tools',
			desc:'Outstanding automatic identification software to enhance your trading experience. The Radar Indicator is an online charting software for technical analysis and trading that provides a range of actionable trading aids, accurate research, and automatic monitoring of market dynamics and trends, allowing you to receive signals when new market trends are forming.'
		},
		indexFour:{
			title:'NOVEMBER SPECIAL<br/> OFFER'
		},
		indexFive:{
			title:'Latest Results',
			titleList:[
				{id:1,title:"Currency Pair"},
				{id:2,title:"Buy Sell"},
				{id:3,title:"Closed at"},
				{id:4,title:"Hit TP/SL"},
				{id:5,title:"Proft Loss"}
			],
			list:[
				{id:1,currencyPair:"EUR/CAD",view:"view",buySell:"SELL",ClosedAt:"1.5450",hit:"TP-2",proftLoss:"+ 50 pips"},
				{id:2,currencyPair:"CHF/JPY",view:"view",buySell:"SELL",ClosedAt:"116.62",hit:"TP-2",proftLoss:"+ 100 pips"},
				{id:3,currencyPair:"EUR/USD",view:"view",buySell:"SELL",ClosedAt:"1.2077",hit:"TP-2",proftLoss:"+ 50 pips"},
				{id:4,currencyPair:"NZD/CHF",view:"view",buySell:"SELL",ClosedAt:"0.6250",hit:"TP-2",proftLoss:"+ 50 pips"}
			]
		},
		indexSix:{
			title:'Signal Indicator Software',
			desc:"√ Register your account <br/> <div>Register as our member in 2 minutes</div> <br/> √ Get value <br/> <div>•\tGet trading signals from experts</div>  <div>•\tGet a Radar Software Program</div> <br/> √ Easy trading <br> <div>Whether you're traveling, sleeping, you'll never miss a deal again!</div>",
			cover:require("@/assets/images/banner/toolsCover.png"),
			list:[
				{id:1,title:'Excellent automatic identification software'},
				{id:2,title:'Operational trading aids'},
				{id:3,title:'Automatically monitor market dynamics and trends'}
			],
			newList:[
				{id:1,img:require("@/assets/images/banner/tools01.jpg"),title:"Top performance, powerful charts",desc:"With more than 80 indicators, 50 drawing tools, 14 periods, and rich customization features, use multiple simultaneous charts in a single layout and apply multiple timeframe analysis."},
				{id:2,img:require("@/assets/images/banner/tools02.jpg"),title:"Don't miss any trading updates",desc:"Currency, index and commodity signals can be copied more efficiently. Using the Trade Copier, it is possible to track signal entries, exits, partial closures, half closures, changes in SL and TP, etc. Execute in real time without missing any trading opportunities."},
				{id:3,img:require("@/assets/images/banner/tools03.jpg"),title:"Effective Risk Management, Efficient Multiple Take Profit",desc:"Saving you time, money and effort, connecting to your account in minutes. When there are multiple Take Profits in a signal, multiple positions are opened for each Take Profit value and the Stop Loss is adjusted accordingly."}
			]
		},
		support:{
			title:"Support when you need it",
			list:[
				{id:1,img:require("@/assets/images/img/support01.png"),title:"Interactive education",desc:"Strengthen your trading skills and knowledge Ask questions and get the answers you need"},
				{id:2,img:require("@/assets/images/img/support02.png"),title:"Real-time trading signals",desc:"Clear the ingress and egress points Innovative Trading Ideas"},
				{id:3,img:require("@/assets/images/img/support03.png"),title:"Daily  Opportunities",desc:"Once you start using our  analysis and education your ability to track your deals will be improved and you will be sure that you will not miss opportunities"},
			]
		},
		indexSeven:{
			title:"Our Products",
			list:[
				{id:1,price:"75",originalPrice:'',desc:"3 Months Membership",button:"Chooce Plan",list:[
					{id:1,title:"Trading with HuiPro Radar Indicator",type:true},
					{id:2,title:"Analyst Live Teaching",type:true},
					{id:3,title:"Daily market overview",type:true},
					{id:4,title:"Short term strategy",type:false},
					{id:5,title:"Long term strategy",type:false},
					{id:6,title:"4-7 target strategies",type:false},
					{id:7,title:"4000+pips per month",type:false},
					{id:8,title:"Online Customer Service",type:false},
					{id:9,title:"Management Tools",type:false},
					{id:10,title:"Real-time chat support",type:false}
				]},
				{id:2,price:"105",originalPrice:'',desc:"6 Months Membership",button:"Chooce Plan",list:[
					{id:1,title:"Trading with HuiPro Radar Indicator",type:true},
					{id:2,title:"Analyst Live Teaching",type:true},
					{id:3,title:"Daily market overview",type:true},
					{id:4,title:"Short term strategy",type:false},
					{id:5,title:"Long term strategy",type:false},
					{id:6,title:"4-7 target strategies",type:false},
					{id:7,title:"4000+pips per month",type:false},
					{id:8,title:"Online Customer Service",type:false},
					{id:9,title:"Management Tools",type:false},
					{id:10,title:"Real-time chat support",type:false}
				]},
				{id:3,price:"170",originalPrice:'',desc:"12 Months Membership",button:"Chooce Plan",list:[
					{id:1,title:"Trading with HuiPro Radar Indicator",type:true},
					{id:2,title:"Analyst Live Teaching",type:true},
					{id:3,title:"Daily market overview",type:true},
					{id:4,title:"Short term strategy",type:false},
					{id:5,title:"Long term strategy",type:false},
					{id:6,title:"4-7 target strategies",type:false},
					{id:7,title:"4000+pips per month",type:false},
					{id:8,title:"Online Customer Service",type:false},
					{id:9,title:"Management Tools",type:false},
					{id:10,title:"Real-time chat support",type:false}
				]},
			]
		}
	},
	chiefAnalyst:{
		banner:{
			title:"Chief Analyst Live",
			desc:"Global Chinese brand real-time trading live"
		},
		teacherTitle:"Teacher's introduction",
		teacherList:[
			{id:1,title:'k-God',desc:"Since 2008 into the trading market, through the foreign exchange, stock market, futures and other markets in-depth study, summarize the law of price fluctuations, to build a set of trend-following trading system - K-bit method. K-bit method is committed to capturing the starting point of the trend, holding positions waiting for the trend of the profit, suitable for short-medium and long-term trading trend friends.",img:require("@/assets/images/banner/teacher001.png")},
			{id:2,title:'Supervise',desc:"16 years of experience in international financial markets. For international futures, foreign exchange market, stock market securities have in-depth research. Has maintained 37 consecutive EIA data analysis completely correct trading record, to help the majority of traders from the financial beginner to become a master of the market transactions. Using the Martin trading method, he is able to steadily capture the trading opportunities in each market, continuously accumulate funds, and complete the dream of doubling the trading capital.",img:require("@/assets/images/banner/teacher002.png")},
			{id:3,title:'commander',desc:"Entered the investment market in 2010, with wave theory as the mainstream analysis system, starting from macro fundamentals, technical aspects of pattern identification, looking for pricing unreasonable opportunities from the market, to capture the value of the return of the top and bottom of the gains. Believes in the concept of no rules in the market and rules in the mind. Original 'cordon sanitaire trading' trend trading method, committed to the minimum risk to win the maximum profit, the correct judgement requires patience and perseverance.",img:require("@/assets/images/banner/teacher003.png")},
		]
	},
	indicatorAnalyst:{
		banner:{
			title:'Indictor Education Iive',
			desc:"Global leading brand financier-fintech real-time  live  trading studio since 2016"
		},
		title:'Our advantage',
		img:require("@/assets/images/banner/indicatorBanner.png"),
		list:[
			{id:1,title:"Real time market analysis by chief trader"},
			{id:2,title:"Explain indicator techniques"},
			{id:3,title:"Judging transaction trends"},
			{id:4,title:"Community online interaction"}
		]
	},
	comment:[
		{id:1,title:"julius ngowijulius",img:require("@/assets/images/index/indexBg01.png"),time:"13th june 2020",star:4,desc:"ust payed year subscription and on 3 days it was payed withtake profit. The best team in forex all respect from me  slenas hit team in forex all respect from me  slenas hi"},
		{id:2,title:"julius ngowijulius",img:require("@/assets/images/index/indexBg02.png"),time:"13th june 2020",star:4,desc:"ust payed year subscription and on 3 days it was payed withtake profit. The best team in forex all respect from me  slenas hit team in forex all respect from me  slenas hi"},
		{id:3,title:"julius ngowijulius",img:require("@/assets/images/index/indexBg03.png"),time:"13th june 2020",star:4,desc:"ust payed year subscription and on 3 days it was payed withtake profit. The best team in forex all respect from me  slenas hit team in forex all respect from me  slenas hi"},
	],
	pay:[
		{id:1,title:"First name",required:true,type:'text',value:'',valueId:''},
		{id:2,title:"Last name",required:true,type:'text',value:'',valueId:''},
		{id:3,title:"Country/Region",required:true,type:'select',value:'',valueId:''},
		{id:4,title:"Street address",required:false,type:'text',value:'',valueId:''},
		{id:5,title:"Town/City",required:false,type:'text',value:'',valueId:''},
		{id:6,title:"ZIP Code",required:false,type:'text',value:'',valueId:''},
		{id:7,title:"Phone",required:true,type:'text',value:'',valueId:''},
		{id:8,title:"Email address",required:true,type:'text',value:'',valueId:''},
	],
	paymentMethods:{
		title:"Payment methods",
		list:[
			{id:1,title:"Credit Card/Debit Card",desc:"Pay with your credit/debit card",show:false,list:[
				{id:1,title:"Name",required:true,type:'text',value:'',valueId:''},
				{id:2,title:"Card Number",required:true,type:'text',value:'',valueId:''},
				{id:3,title:"Expiry Date",required:true,type:'date',value:'',valueId:''},
				{id:4,title:"Card Code (CVC)",required:true,type:'text',value:'',valueId:''},
			]},
			{id:2,title:"PayPal",desc:"",show:true,list:[]},
			{id:3,title:"Airwallex",desc:"",show:false,list:[]}
		]
	},
	faq:{
		title:"FAQ",
		desc:"",
		list:[
			{id:1,title:"",desc:"",list:[
				{id:1,title:"How can you help me?",desc:"Trading Signals:We can suggest some high-quality services which are especially appropriate for the beginners and active traders, as well. <br> Trading Tools: The service is both efficient and quite simplified. And because of these advantages each trader can gain more from Trading at all.",show:false},
				{id:2,title:"What is the success rate of your signals?",desc:"Our signals have a high success rate<br>\n" +
						"Accuracy more than 80-90% \n" +
						"<br> Easy copy-trade signals for beginners",show:false},
				{id:3,title:"How are the signals delivered?",desc:"The signals are delivered through our exclusive channels on Telegram.\n" +
						" After subscribing to our VIP plan, you will receive access from our experts to these channels where the signals are regularly shared.",show:false},
				{id:4,title:"How often are the signals updated?",desc:"We provide real-time updates \n" +
						"5 to 50 signals daily across our multiple channels -\n" +
						"long and short signals.",show:false},
				{id:5,title:"What is WeProTrade?",desc:"We are a team of traders with 3-year-old experience in thе Exchange sphere. <br> During the years we have decided to unite all of our knowledge and skills and make them available for you, our customers.<br>\n" +
						"Our team of experts analyses and focus on the best profitable opportunities.<br>\n" +
						"We give information about:<br>\n" +
						"When is the best time for you to trade?<br>\n" +
						"What is the best way for you to trade?",show:false},
			]}
		]
	},
	payInfo:[
		{id:1,key:"orderNo",show:true,title:"Merchandise order：",value:""},
		{id:2,key:"amt",show:true,title:"Amount paid：",value:""},
		{id:3,key:"goodsName",show:true,title:"Trade name：",value:""},
		{id:4,key:"",show:false,title:"Order quantity：",value:""},
		{id:5,key:"channelOrderNo",show:true,title:"Pay an order：",value:""},
		{id:6,key:"payWay",show:true,title:"Payment method：",value:""},
		{id:7,key:"createTime",show:true,title:"Purchase time：",value:""},
		{id:8,key:"userName",show:true,title:"Name：",value:""},
		{id:9,key:"",show:false,title:"Street address：",value:""},
		{id:10,key:"",show:false,title:"Town/City：",value:""},
		{id:11,key:"",show:false,title:"ZIP Code ：",value:""},
		{id:12,key:"phone",show:true,title:"Phone ：",value:""},
		{id:13,key:"email",show:true,title:"Email address：",value:""},
		{id:14,key:"",show:false,title:"Order notes ：",value:""},
	]
}
